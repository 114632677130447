import React, { useEffect } from "react"
// atm packages
import { GetStartedInfo, SignupForm } from "@components"
import { SignupLayout } from "@layout"
import { usePublishWebEvent } from "@services"
import { TRACKING_ATM } from "@constants"

const Signup = () => {
  const [publishWebEvent] = usePublishWebEvent()

  useEffect(() => {
    publishWebEvent({ name: TRACKING_ATM.screen_view_reg })
  }, [])

  return (
    <SignupLayout
      hideJoinDownload
      navBarNoLinks
      leftContent={<SignupForm />}
      rightContent={<GetStartedInfo />}
    ></SignupLayout>
  )
}

export default Signup
